<template>
  <div class="misc-wrapper">
      <div class="w-100 text-center p-4">
        <b-row>
      <b-col
        v-for="(data,index) in stats.results"
        :key="index"
        md="6"
        xl="4"
      >
        <b-card
          :bg-variant="data.availColor"
          text-variant="white"
          class="p-0"
        >
          <b-card-title class="text-white mt-0 mb-0" style="font-size: 2.8rem">
            {{ data.Name }}
          </b-card-title>
          <b-card-text style="font-size: 1.5rem">
            {{data.availability}} | {{data.activity}}
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
      </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow, BCol, BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
  },
  data() {
    return {
      solidColor: [
        { bg: 'primary', title: 'Primary card title' },
        { bg: 'secondary', title: 'Secondary card title' },
        { bg: 'success', title: 'Success card title' },
        { bg: 'danger', title: 'Danger card title' },
        { bg: 'warning', title: 'Warning card title' },
        { bg: 'info', title: 'Info card title' },
      ],
      timer:0,
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
      stats: {
  "queryTime": {
    "value": "",
    "DisplayHint": 2,
    "DateTime": ""
  },
  "queryCount": 0,
  "results": [
    {
      "Name": "",
      "UserName": "",
      "availColor":"secondary",
      "availability": "",
      "activity": ""
    }
  ]
},
    }
  },
  mounted() {
    this.$http.get('https://asi-api2.azure-api.net/phone/getPresence?key=f56257100ff6407d8572abbe599a6271').then(res => { this.stats = res.data }),
    this.timer = setInterval(() => {
      this.$http.get('https://asi-api2.azure-api.net/phone/getPresence?key=f56257100ff6407d8572abbe599a6271').then(res => { this.stats = res.data })
    }, 30000)
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
